
<template>
<div class="">
  <h4 class="mb-3">Subscription Item</h4>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="subscription" class="col-12">
      <div class="card mb-5">
        <div class="card-body">
          <div class="row mb-3 justify-content-between align-items-start">
            <div class="col-10 col-sm-8">
              <h4 class="mb-2">Plan: {{subscription.plan.title}}</h4>
              <div class="mb-2">
                <strong class="mr-1">Status: <span v-html="statusBadge(subscription.status)"></span></strong>
              </div>
              <div class="mb-3" v-if="subscription.payment_method">
                <payment-method-card :item="subscription.payment_method"/>
              </div>
              <div>
                <h4 class="fw-bold"> {{subscription.plan.title}} Plan subscription at ${{subscription.plan.actual_price | money_format }} USD  per {{subscription.plan.billing_period}}</h4>
                <p>Subscription current period is between <strong>{{subscription.current_period_start | date_time(1)}}</strong> and <strong>{{subscription.current_period_end | date_time(1)}}</strong></p>
              </div>
            </div>
            <div class="col-2 col-sm-4 text-sm-right">
              <b-dropdown variant="light" size="sm" right>
                <template slot="button-content">
                  <i class="ri-more-2-fill"></i>
                </template>
                <!-- <b-dropdown-item-button @click.prevent="refreshStatus(subscription.id)">
                   <i class="ri ri-refresh-line align-bottom me-1"></i> Refresh status
                </b-dropdown-item-button> -->
                <b-dropdown-item :to="`/users/${subscription.buyer.id}`">
                   <i class="ri ri-checkbox-blank-circle-line align-bottom me-1"></i> View user 
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-12">
              <h6 class="mb-2">Payment History</h6>
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Description</th>
                      <th scope="col">Date</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(payment, inIndex) in subscription.payments" :key="inIndex">
                      <td><div style="min-width: 10rem; max-width: 20rem;">{{payment.description}}</div></td>
                      <td><div style="min-width: 6rem;">{{payment.payment_date | date_time(1) }}</div></td>
                      <td><div style="min-width: 3rem;">${{payment.paid_amount | money_format }}</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import PaymentMethodCard from '@/components/cards/PaymentMethodCard.vue'

export default {
  components: { IsLoading, PaymentMethodCard },
  data(){
    return {
        isLoading: true,
        subscription: null
    }
  },
  watch: {
    '$route.params.subId': function (id){
      if(id) this.fetchSubscription()
    },
  },
  methods:{
    fetchSubscription(){
      this.isLoading = true
      this.$http.get(`/subscriptions/${this.$route.params.subId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.subscription = response.data.data
        }
      })
    }
  },
  created(){
    this.fetchSubscription()
  }
}

</script>

<style lang="scss">

</style>
